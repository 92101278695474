<script setup lang="ts">

const { menuOpen, setMenuState, closeMenu} = useUiState()

const toggleMobileMenu = useThrottleFn(() => {
  setMenuState({
    state: !menuOpen.value,
    savedPos: document.getElementById("mainContent")?.getBoundingClientRect().top || 0, // Fix main content at this value
    scrollBack: !menuOpen.value ? window.scrollY : undefined, // Current scroll pos
  });
}, 400);

// Reset state after route change
const router = useRouter();
router.afterEach(() => {
  // if (servicesActive.value) {
  //   toggleServices(false);
  // }
  setTimeout(() => closeMenu(), 100);
});

const { useEvent } = useGlobalEvents()

const { useDarkMode, useDyslexicFont, useGlobalSiteSearch } = useAppConfig().settings.ui


const { header } = useAppConfig().settings;

const { data: mainMenu, error } = await useAsyncData('fetch_main_menu', async () => {
  if (!header.fetch) return header.menu

  // TODO should actually implement fetch!
  return header.menu
})


</script>

<template>
  <header
    class="z-30 w-full top-0 sm:-top-[32px]"
    :class="[
      menuOpen ? 'fixed' : 'sticky',
    ]"
  >
    <div
      v-if="!header.disableTopMenu"
      class="hidden sm:block relative z-30 bg-gray-100 dark:bg-gray-700 transition-colors py-1 w-full"
      @mouseenter="useEvent('closeDropdown')"
    >
      <UContainer class="flex items-stretch justify-between">
        <div class="flex flex-row gap-5">
          <slot name="top-nav-widgets" />
        </div>
        <div class="flex flex-row gap-5">
          <slot name="widgets-dekstop" />
          <div class="flex flex-row -ml-3 -mr-2 gap-2">
            <!-- <SearchWidget v-if="useGlobalSiteSearch" /> -->
            <ThemeToggle v-if="useDarkMode" />
            <FontToggle v-if="useDyslexicFont" />
          </div>
        </div>
      </UContainer>
    </div>
    <div
      ref="nav"
      class="relative z-20 bg-white dark:bg-gray-800 shadow-lg transition-colors w-full"
    >
      <UContainer
        class="flex items-stretch justify-between py-1.5 sm:py-3.5 min-h-[60px] sm:min-h-[76px]"
      >
        <!-- Logo  -->
        <div class="w-auto flex">
          <div class="flex flex-wrap items-center">
            <Logo class="w-40 max-w-full" />
          </div>
        </div>
        <!-- Main menu -->
        <div class="flex">
          <UButton
            color="gray"
            variant="ghost"
            class="w-[48px] -mr-2 swap sm:hidden"
            block
            @click="toggleMobileMenu"
          >
            <UIcon
              name="i-heroicons-bars-3-bottom-right-20-solid"
              class="sm:hidden w-6 h-6"
              :class="!menuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-45'"
            />
            <UIcon
              name="i-heroicons-x-mark-20-solid"
              class="sm:hidden w-6 h-6"
              :class="menuOpen ? 'opacity-100 rotate-0' : 'opacity-0 rotate-45'"
            />
          </UButton>

          <div class="hidden sm:flex flex-wrap items-stretch gap-5 sm:gap-12">
            <MainMenu :menu="mainMenu || []" />
            <slot name="main-menu-widgets" />
          </div>
          <LazyMobileMenu
            v-if="menuOpen"
            :menu="mainMenu || []"
            @close="closeMenu"
          >
            <template #submenu>
              <slot name="widgets-mobile" />
            </template>
          </LazyMobileMenu>
        </div>
      </UContainer>
    </div>
  </header>
</template>
