<script setup>
const props = defineProps({
  label: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change"]);
const { font, changeFont } = useTheme();

watch(font, (newVal) => {
  const { trackEvent } = useTracking();
  trackEvent("font_change", {
    event_category: "ui",
    event_label: "theme",
    event_value: newVal,
  });
  emit("change");
});

const buttonProps = computed(() => {
  if (!props.label) return {};
  return {
    color: "gray",
    variant: "ghost",
    block: true,
    class: "justify-between",
    size: "md",
  };
});
const btn = resolveComponent("UButton");

const labelText = computed(() => font.value === "default" ? "Gebruik dyslexie letter" : "Gebruik standaard letter")
</script>

<template>
  <UTooltip :text="labelText">
    <component
      :is="label ? btn : 'div'"
      v-bind="buttonProps"
      @click="() => (label ? changeFont() : null)"
    >
      <span v-if="label">{{
        labelText
      }}</span>
      <label class="w-auto h-full swap" :class="!label ? 'px-2' : 'mx-0'">
        <ClientOnly>
          <!-- this hidden checkbox controls the state -->
          <input type="checkbox" :checked="font === 'default'" @click="changeFont" />

          <UIcon
            name="i-mdi-format-font"
            class="w-4 h-4 transition-colors"
            :class="font === 'default' ? 'rotate-0' : 'rotate-[360deg] text-primary-400'"
          />
        </ClientOnly>
      </label>
    </component>
  </UTooltip>
  
</template>
