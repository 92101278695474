<script setup lang="ts">

const props = defineProps<{
  menu: MenuItem[]
}>()

const isHovering: Ref<string | null> = ref(null);
const popoverX: Ref<number | null> = ref(null);
const popoverHeight: Ref<number | null> = ref(null);
const itemWidth: Ref<number> = ref(0); // width of hovered item
const arrowX: Ref<number> = ref(0) // x pos of arrow


interface Position {
  top: number;
  right: number;
}

function getPosition(element: HTMLElement): Position {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const parentRect = element.parentElement?.getBoundingClientRect();
  if (!parentRect) {
    throw new Error("Parent element does not exist or has no bounding client rectangle.");
  }
  return {
    top: rect.top + scrollTop - parentRect.top,
    right: parentRect.right - rect.right - scrollLeft,
  };
}

const setHover = async (event: Event, itemId: string) => {
  if (event.target) {
    isHovering.value = itemId;
    await nextTick(() => {
        const height = document.getElementById(itemId)?.offsetHeight;
        popoverHeight.value = height ? height : 0;
        const target = event.target as HTMLElement | undefined
        try {
          if (target) {
            // Conidtion for right position, so the dropdown does not overflow the viewport
            const isLastItem = props.menu.findIndex(item => item.id === itemId) === props.menu.length -1
            const right = window.innerWidth > 1450 ? 50 : isLastItem ? 12 : 50
            const width = target?.offsetWidth;
            arrowX.value = right + (width / 2) - (9) // subtract 9 which is half the arrow width
            popoverX.value = getPosition(target).right - right;
            itemWidth.value = width ?? 0;
          }
          
        } catch (error) {
          console.error(error);
        }
      });
  }
};

const unHover = () => {
  isHovering.value = null;
  popoverX.value = null;
  popoverHeight.value = null;
  itemWidth.value = 0;
};

const target = ref(null)
onClickOutside(target, unHover)


const { useListen } = useGlobalEvents()
useListen("closeDropdown", () => {
  unHover();
});
</script>

<template>
  <nav class="w-auto flex relative">
    <ul class="flex text-sm flex-row w-full items-stretch justify-center gap-5 sm:gap-12">
      <li
        v-for="item in menu"
        :key="item.id"
        class="grid place-content-center font-bold"
        @mouseenter="(evt) => setHover(evt, item.id)"
      >
        <NuxtLink v-if="!!item.to" :to="item.to" target="_blank" external>
          {{ item.name.toLowerCase() }}
        </NuxtLink>
        <span v-else class="cursor-pointer select-none">{{ item.name.toLowerCase() }}</span>
      </li>
    </ul>
    <Transition name="fade-up" mode="out-in">
      <UCard
        v-if="
          !!isHovering && !!menu.find((i) => i.id === isHovering)?.children?.length
        "
        ref="target"
        :style="{
          right: !!popoverX ? popoverX + 'px' : 'auto',
          height: !!popoverHeight ? popoverHeight + 'px' : '0px',
          '--item-width': itemWidth + 'px',
        }"
        class="absolute bg-white rounded-lg w-[600px] top-[4.75rem] transition-all duration-[350ms] ease-in-out overflow-visible"
        :ui="{
          body: {
            padding: 'p-0 sm:p-0'
          },
          shadow: 'shadow-lg',
        }"
      >
        <div class="triangle" :style="{right: arrowX + 'px'}"></div>
        <div v-for="item in menu" :key="item.id">
          <Transition name="fade-sub-menu" mode="out-in">
            <SubMenu
              v-if="isHovering === item.id"
              :id="item.id"
              :item-id="item.id"
              :children="item.children"
            />
          </Transition>
        </div>
      </UCard>
    </Transition>
  </nav>
</template>

<style lang="postcss">
.triangle {
  --width: 18px;
  --height: calc(sqrt(3) / 2.5 * var(--width));
  position: absolute;
  top: calc(-1 * var(--height) + 1px);
  width: 0;
  height: 0;
  border-left: calc(var(--width) / 2) solid transparent; /* Half the width of your triangle */
  border-right: calc(var(--width) / 2) solid transparent; /* Half the width of your triangle */
  border-bottom: var(--height) solid; /* Height of your triangle (sqrt(3)/2 * width) */
  transition: all 0.2s 0.05s ease-in-out;
  @apply border-b-white dark:border-b-gray-700;
}

.fade-sub-menu-enter-active {
  transition: all 0.25s 0.1s ease-in-out;
}

.fade-sub-menu-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-sub-menu-enter-from {
  transform: translateX(10px);
  opacity: 0;
}

.fade-sub-menu-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.fade-up-enter-active {
  transition: all 0.15s 0.1s ease-in-out;
}

.fade-up-leave-active {
  transition: all 0.15s ease-in-out;
}

.fade-up-enter-from {
  transform: translatey(5px);
  opacity: 0;
}

.fade-up-leave-to {
  transform: translateY(-5px);
  opacity: 0;
}



</style>
