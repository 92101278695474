<template>
  <ul
    class="flex flex-wrap"
    :class="orientation === 'vertical' ? 'flex-col' : ''"
  >
    <li v-for="social in socials">
      <NuxtLink 
        class="w-auto p-4 hover:opacity-80 transition-opacity grid place-items-center"
        :to="social.url" 
        target="_blank" 
        :title="'Naar ' + social.label" 
        :aria-label="'Naar ' + social.label"
      >
        <UIcon :name="social.icon" class="w-6 h-6" :class="socialList.color[color]" />
      </NuxtLink>
    </li>
  </ul>
</template>

<script lang="ts" setup>

import type { SocialChannel } from '../../types'

withDefaults(
  defineProps<{
    orientation?: 'horizontal' | 'vertical'
    socials?: SocialChannel[],
    color?: BaseColor
}>(), {
    orientation: 'horizontal',
    socials: () => [],
    color: 'primary'
  }
)

const { socialList } = useAppConfig().components

</script>

<style>

</style>