<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    subfooterColor?: 'primary' | 'gray'
    subfooter?: 'fluid' | 'contained'
  }>(), {
    subfooter: 'contained',
    subfooterColor: 'primary'
})

const { footer } = useAppConfig().settings;
const { data: footerMenu, error } = await useAsyncData('fetch_footer_menu', async () => {
  if (footer.disableFooterMenu) return []
  if (!footer.fetch) return footer.menu

  // TODO should actually implement fetch!
  return footer.menu
})
</script>

<template>
  <footer class="fluid pt-8 sm:pt-12 md:pt-16 sm:pt-10" :class="subfooter === 'contained' ? 'pb-6 px-4' : ''">
    <!-- Main footer -->
    <UContainer v-if="!footer.disableFooterMenu">
      <div class="grid footer-grid gap-8 pb-8 sm:pb-12 md:pb-16">
        <div
          v-for="group in footerMenu"
        >
          <h4>{{ group.label }}</h4>
          <ul class="flex flex-col gap-1">
            <li
              v-for="link in group.children"
            >
              <UButton 
                :to="link.to" 
                :external="link.external"
                :target="link.external ? '_blank' : '_self'"
                variant="ghost"
                size="xs"
                color="gray"
                class="-mx-2"
                :ui="{
                  color: { gray: { ghost: 'hover:bg-gray-100 dark:hover:bg-gray-700/50'}} 
                }"
              >
                <span class="leading-snug text-gray-700 hover:text-gray-900 dark:text-gray-300 hover:dark:text-gray-100 transition-colors font-normal">
                  {{ link.name }}
                </span>
              </UButton>
            </li>
          </ul>
        </div>
      </div>
    </UContainer>
   
    <!-- Subfooter -->
    <div 
      class="py-8 px-2 sm:px-0"
      :class="[
        subfooter === 'contained' ? 'fluid-inner rounded-3xl mx-auto' : 'w-full',
        subfooterColor === 'primary' ? 'bg-main-25 dark:bg-gray-800' : 'bg-gray-100 dark:bg-gray-800',
      ]"
    >
      <UContainer>
        <div class="flex flex-col md:flex-row md:justify-between gap-6 md:items-center">
          <div class="basis-3/4 lg:basis-1/2">
              <slot name="subfooter-left" />
          </div>
          <div class="hidden lg:block lg:basis-1/4">
            <slot name="subfooter-center" />
          </div>
          <div class="md:basis-1/2 lg:basis-1/4">
            <slot name="subfooter-right" />
          </div>
        </div>
      </UContainer>
    </div>
  </footer>
</template>


<style lang="postcss">
.footer-grid {
  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  }
}

</style>