<script setup>
const  { image } = useAppConfig().settings.meta;

useSeoMeta({
  "og:locale": "nl",
  "og:image": image,
  image: image,
  twitterImage: image,
  twitterCard: "summary",
});

// TheHeader contains and expanding services section, which overlays the page content, but not the footer. So the header emits these events, to manually hide the footer!
// The overflow-hidden class prevents scrolling of the page laying underneath
const { menuOpen, scrollBackPos, savedPos } = useUiState()

watch(menuOpen, async (val, oldVal) => {
  if (oldVal && !val) {
    await nextTick(() => {
      window.scrollTo(0, scrollBackPos.value);
    });
  }
});



</script>

<template>
  <div>
    <NuxtLayout name="default">
      <div
        id="mainContent"
        class="flex flex-col min-h-[calc(100dvh)] sm:min-h-[calc(100dvh-115.2px)] w-full"
        :style="[
          menuOpen ? { position: 'fixed', top: savedPos + 60 + 'px' } : null,
        ]"
      >
        <div class="grow flex flex-col min-h-[calc(100dvh)] z-30">
            <TheHeader />
            <div class="grow max-w-[100vw]">
              <UContainer>
                <main class="relative h-full">
                  <slot />
                </main>
              </UContainer>
            </div>

            <UContainer>
              <TheFooter />
            </UContainer>
          </div>
      </div>
      <div id="serviceProvider" />
    </NuxtLayout>
  </div>
</template>
