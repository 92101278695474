import mitt from 'mitt'

// TODO this could use better typing
//TODO should be refactored for https://vueuse.org/core/useEventBus/#useeventbus
type ApplicationEvents = {
  closeDropdown: void
};

const emitter = mitt<ApplicationEvents>()

export const useEvent = emitter.emit
export const useListen = emitter.on

export const useGlobalEvents = () => {
  const useEvent = emitter.emit
  const useListen = emitter.on
  return {
    useEvent,
    useListen
  }
}