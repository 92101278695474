<script setup lang="ts">

const props = defineProps<{
  link: MenuItem;
  grouped?: boolean; // is it part of a group?
}>();

// TODO fire query for linked item if no "to" value was provided
const to = computed(() => {
  return props.link.to || "";
});

const icon = computed(() => {
  if (!props.link.icon) return null;
  return props.link.icon.replaceAll("_", "-");
});
</script>

<template>
  <UButton 
    v-if="link.type === 'link'" 
    :to="to" 
    :target="link.external ? '_blank' : '_self'" 
    :external="link.external"
    variant="ghost"
    color="gray"
    class="-mx-2"
    :class="(!link.icon && !link.image) || grouped ? 'ml-4' : ''"
    :ui="{
      color: { gray: { ghost: 'dark:hover:bg-gray-700/50'}} 
    }"
  >
    <div
      class="flex flex-row items-start gap-2"
      
    >
          <UIcon
            v-if="icon?.startsWith('i-')"
            :name="icon"
            class="relative  top-[1.5px] text-primary-400 shrink-0"
            :class="link.iconAsTile ? 'bg-gray-200 dark:bg-gray-700 rounded-md relative top-1 mr-2' : 'w-4 h-4'"
          />
          <Icon
            v-else-if="icon"
            :name="'material-symbols:' + icon"
            class="relative w-4 h-4 top-[1.5px] text-primary-400 shrink-0 "
          />
          <NuxtImg
            v-else-if="!!link.image"
            :src="'onderwijsloket/directus/' + link.image"
            width="40"
            height="40"
            fit="fill"
            class="rounded-md relative top-1 mr-2 shrink-0"
          />
      <div class="flex flex-col text-left">
        <span class="leading-snug" :class="!link.description ? 'text-gray-700 dark:text-gray-300 font-normal' : 'font-bold mb-1'">{{
          link.name
        }}</span>
        <span v-if="!!link.description" class="text-xs text-gray-500 leading-normal font-normal">{{
          link.description
        }}</span>
      </div>
    </div>
  </UButton>
</template>
