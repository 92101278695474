<script setup>
const props = defineProps({
  label: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change"]);
const { colorMode, changeColorMode } = useTheme();

watch(colorMode, (newVal) => {
  const { trackEvent } = useTracking();
  trackEvent("theme_change", {
    event_category: "ui",
    event_label: "theme",
    event_value: newVal,
  });
  emit("change");
});

const buttonProps = computed(() => {
  if (!props.label) return {};
  return {
    color: "gray",
    variant: "ghost",
    block: true,
    class: "justify-between",
    size: "md",
  };
});
const btn = resolveComponent("UButton");

const labelText = computed(() =>  colorMode.value === "dark" ? "Naar lichte modus" : "Naar donkere modus")
</script>

<template>
  <UTooltip :text="labelText">
    <component
      :is="label ? btn : 'div'"
      v-bind="buttonProps"
      @click="() => (label ? changeColorMode() : null)"
    >
      <span v-if="label">
        {{ labelText }}</span
      >
      <label class="w-auto h-full swap" :class="!label ? 'px-2' : 'mx-0'">
        <ClientOnly>
          <!-- this hidden checkbox controls the state -->
          <input type="checkbox" :checked="colorMode === 'dark'" @click="changeColorMode" />

          <!-- moon icon -->
          <UIcon
            :name="colorMode === 'dark' ? 'i-heroicons-sun-20-solid' : 'i-heroicons-moon-20-solid'"
            class="w-4 h-4 transition-colors"
            :class="colorMode === 'dark' ? 'rotate-45' : 'skew-x-4'"
          />
        </ClientOnly>
      </label>
    </component>
  </UTooltip>
</template>
