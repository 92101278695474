<template>
  <BaseHeader>
    <template #top-nav-widgets>
      <!-- Widgets that are displayed on the left hand side of top navigation. Should be individual elements, because they are placed within a flex container -->
    </template>
    <template #main-menu-widgets>
      <!-- Widgets that are displayed right of the main menu. Slot is an empty space, without a flex container -->
    </template>
    <template #widgets-mobile>
      <!-- Widgets that are displayed at the bottom of the mobiel menu. Usually a combination of both desktop widget area's. Widgets are placed in a single animated  div that is considered a single menu item -->
    </template>
  </BaseHeader>
</template>

<script lang="ts" setup>

</script>

<style>

</style>