<script setup lang="ts">

const socials = useAppConfig().settings.meta.socials
</script>

<template>
  <BaseFooter
    
  >
    <template #subfooter-left>
      <div class="text-sm text-gray-500 md:flex md:items-center">
        <Logo class="w-48" />
        
      </div>
      <div class="mt-4 flex flex-col sm:flex-row gap-4 md:gap-8 text-gray-500 text-2xs">
        <span>© {{ new Date().getFullYear() }}. Alle rechten voorbehouden</span>
        <span>Onderdeel van <NuxtLink to="https://onderwijs.in" external class="underline text-primary-500 font-bold">stichting Onderwijs in</NuxtLink></span>
      </div>
      
    </template>
    <template #subfooter-right>
      <SocialList :socials="socials" class="flex flex-wrap -ml-4 md:justify-end" />
    </template>
  </BaseFooter>
</template>
